.title {
  font-family: "Rock Salt", sans-serif;
  font-style: italic;
  white-space: nowrap;
}

.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 0.8vw;
  color: rgba(165, 141, 141, 0.4);
  text-transform: uppercase;
  letter-spacing: 1em;
  text-align: center;
  position: absolute;
  left: 17%;
  animation: glitch-2 5s 5.02s infinite;
}

.container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
}

.hero,
.glow {
  color: #dfbfbf;
  position: relative;
  font-size: 7vw;
  animation: glitch 5s 5s infinite;
}

.hero::before,
.glow::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: -5px 0 magenta;
  /* background: black; */
  overflow: hidden;
  top: 0;
  animation: noise-1 3s linear infinite alternate-reverse,
    glitch 5s 5.05s infinite;
}

.hero::after,
.glow::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -5px 0 lightgreen;
  /* background: black; */
  overflow: hidden;
  top: 0;
  animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}

.glow {
  text-shadow: 0 0 1000px #dfbfbf;
  color: transparent;
  position: absolute;
  top: 0;
}

.specialEffect1 {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  mix-blend-mode: difference;
}

.specialEffect1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 0.5%,
    transparent 1%
  );
  animation: fudge 7s ease-in-out alternate infinite;
}

.type-container{
  display: inline-block;
}
.typed-out{
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  animation: typing 1.2s steps(20, end) forwards, blink 1.3s infinite;
  font-size: 1.6rem;
  width: 0;
}
.a{
    color:lightgreen
}
.q {
    color:orange
}
.lrg {
    font-size: 1.8em;
    padding-right: 0.2em
}
.md {
    font-size: 1.4em;
    padding-right: 0.3em
}
.sm {
    font-size: 0.75em;
}
.type-container p:nth-child(2){
  animation: type 8s steps(60, end);
}
.type-container p a{
  text-decoration: none;
}
.type-container span{
  animation: blink 1s infinite;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes type{ 
  from { width: 0; } 
} 
@keyframes blink {
  from { border-color: transparent }
  to { border-color: orange; }
}

@media only screen and (max-width: 767px) {
  .specialEffect1 {
    display: none;
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

// Animations
@keyframes glitch {
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}
@keyframes glitch-2 {
  1% {
    transform: rotateX(10deg) skewX(70deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes noise-1 {
  3.3333333333% {
    clip-path: inset(17px 0 48px 0);
  }
  6.6666666667% {
    clip-path: inset(15px 0 8px 0);
  }
  10% {
    clip-path: inset(69px 0 7px 0);
  }
  13.3333333333% {
    clip-path: inset(93px 0 7px 0);
  }
  16.6666666667% {
    clip-path: inset(6px 0 22px 0);
  }
  20% {
    clip-path: inset(71px 0 18px 0);
  }
  23.3333333333% {
    clip-path: inset(9px 0 26px 0);
  }
  26.6666666667% {
    clip-path: inset(83px 0 9px 0);
  }
  30% {
    clip-path: inset(99px 0 1px 0);
  }
  33.3333333333% {
    clip-path: inset(16px 0 71px 0);
  }
  36.6666666667% {
    clip-path: inset(80px 0 4px 0);
  }
  40% {
    clip-path: inset(25px 0 3px 0);
  }
  43.3333333333% {
    clip-path: inset(94px 0 7px 0);
  }
  46.6666666667% {
    clip-path: inset(6px 0 73px 0);
  }
  50% {
    clip-path: inset(71px 0 16px 0);
  }
  53.3333333333% {
    clip-path: inset(38px 0 61px 0);
  }
  56.6666666667% {
    clip-path: inset(85px 0 8px 0);
  }
  60% {
    clip-path: inset(13px 0 20px 0);
  }
  63.3333333333% {
    clip-path: inset(39px 0 53px 0);
  }
  66.6666666667% {
    clip-path: inset(14px 0 37px 0);
  }
  70% {
    clip-path: inset(59px 0 9px 0);
  }
  73.3333333333% {
    clip-path: inset(86px 0 5px 0);
  }
  76.6666666667% {
    clip-path: inset(97px 0 3px 0);
  }
  80% {
    clip-path: inset(57px 0 28px 0);
  }
  83.3333333333% {
    clip-path: inset(49px 0 24px 0);
  }
  86.6666666667% {
    clip-path: inset(45px 0 18px 0);
  }
  90% {
    clip-path: inset(87px 0 13px 0);
  }
  93.3333333333% {
    clip-path: inset(10px 0 31px 0);
  }
  96.6666666667% {
    clip-path: inset(7px 0 25px 0);
  }
  100% {
    clip-path: inset(31px 0 63px 0);
  }
}
@keyframes noise-2 {
  0% {
    clip-path: inset(34px 0 18px 0);
  }
  3.3333333333% {
    clip-path: inset(76px 0 20px 0);
  }
  6.6666666667% {
    clip-path: inset(70px 0 22px 0);
  }
  10% {
    clip-path: inset(94px 0 1px 0);
  }
  13.3333333333% {
    clip-path: inset(18px 0 72px 0);
  }
  16.6666666667% {
    clip-path: inset(28px 0 59px 0);
  }
  20% {
    clip-path: inset(59px 0 33px 0);
  }
  23.3333333333% {
    clip-path: inset(13px 0 47px 0);
  }
  26.6666666667% {
    clip-path: inset(41px 0 25px 0);
  }
  30% {
    clip-path: inset(22px 0 12px 0);
  }
  33.3333333333% {
    clip-path: inset(15px 0 61px 0);
  }
  36.6666666667% {
    clip-path: inset(39px 0 4px 0);
  }
  40% {
    clip-path: inset(88px 0 8px 0);
  }
  43.3333333333% {
    clip-path: inset(6px 0 76px 0);
  }
  46.6666666667% {
    clip-path: inset(23px 0 50px 0);
  }
  50% {
    clip-path: inset(92px 0 2px 0);
  }
  53.3333333333% {
    clip-path: inset(24px 0 22px 0);
  }
  56.6666666667% {
    clip-path: inset(98px 0 2px 0);
  }
  60% {
    clip-path: inset(12px 0 63px 0);
  }
  63.3333333333% {
    clip-path: inset(70px 0 10px 0);
  }
  66.6666666667% {
    clip-path: inset(100px 0 1px 0);
  }
  70% {
    clip-path: inset(22px 0 70px 0);
  }
  73.3333333333% {
    clip-path: inset(70px 0 24px 0);
  }
  76.6666666667% {
    clip-path: inset(71px 0 9px 0);
  }
  80% {
    clip-path: inset(2px 0 76px 0);
  }
  83.3333333333% {
    clip-path: inset(23px 0 33px 0);
  }
  86.6666666667% {
    clip-path: inset(59px 0 4px 0);
  }
  90% {
    clip-path: inset(42px 0 22px 0);
  }
  93.3333333333% {
    clip-path: inset(49px 0 46px 0);
  }
  96.6666666667% {
    clip-path: inset(42px 0 29px 0);
  }
  100% {
    clip-path: inset(77px 0 18px 0);
  }
}
@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}




// MATRIX CODE BELOW (https://dev.to/tetragius/pure-css-matrix-code-effect-5b6k)

@property --timer {
  syntax: "<integer>";
  inherits: false;
  initial-value: 12;
}
@counter-style lower-macedonian {
  system: alphabetic;
  symbols: "\3044""\308D""\306F""\306B""\307B""\3078""\3068""\3061""\308A""\306C""\308B""\3092""\308F""\304B""\3088""\305F""\410""\411""\412""\413""\414""\402""\415""\416""\417""\418""\408""\41A""\41B""\409""\41C""\41D""\40A""\41E""\41F""\420""\421""\422""\40B""\423""\424""\425""\442""\427""\40F""\428""\1270""\1278""\1290""\1298""\1300""\1308""\1318""\1320""\1328""\1330""\1348""\1350""\3075""\3053""\3048""\3066""\3042""\3055""\304D""\627""\628""\62C""\62F""\647\200D""\648""\632""\62D""\637""\64A""\643""\644""\645""\646";
  suffix: "、";
}
.matrix {
  background-color: none;
  overflow: hidden;
  font-family: monospace;
  opacity:0.33;
}
.l00 {
  left: calc(100% + 20px);
}
.l05 {
  left: 05%;
}
.l10 {
  left: 10%;
}
.l15 {
  left: 15%;
}
.l20 {
  left: 20%;
}
.l25 {
  left: 25%;
}
.l30 {
  left: 30%;
}
.l35 {
  left: 35%;
}
.l40 {
  left: 40%;
}
.l45 {
  left: 45%;
}
.l50 {
  left: 50%;
}
.l55 {
  left: 55%;
}
.l60 {
  left: 60%;
}
.l65 {
  left: 65%;
}
.l70 {
  left: 70%;
}
.l75 {
  left: 75%;
}
.l80 {
  left: 80%;
}
.l85 {
  left: 85%;
}
.l90 {
  left: 90%;
}
.l95 {
  left: 95%;
}
.l100 {
  left: calc(100% - 20px);
}

.t200 {
  top: -400px;
}
.t500 {
  top: -500px;
}
.t180 {
  top: -680px;
}
.t700 {
  top: -700px;
}
.t800 {
  top: -800px;
}
.t900 {
  top: -900px;
}
.t300 {
  top: -300px;
}
.t400 {
  top: -400px;
}
.t250 {
  top: -250px;
}
.t320 {
  top: -320px;
}
.t190 {
  top: -690px;
}
.t520 {
  top: -520px;
}
.t380 {
  top: -680px;
}
.t400 {
  top: -800px;
}
.t600 {
  top: -600px;
}
.t650 {
  top: -650px;
}
.t290 {
  top: -790px;
}
.t530 {
  top: -730px;
}
.t420 {
  top: -720px;
}
.t310 {
  top: -410px;
}
.big div {
  width: 42px !important;
  height: 333px !important;
  font-size: 17px !important;
  color: #008000 !important;
}
.letter {
  position: absolute;
  background-color: none;
  counter-reset: timer-1 calc(var(--timer) + 9) timer-2 calc(var(--timer) + 8) timer-3 calc(var(--timer) + 7) timer-4
    calc(var(--timer) + 6) timer-5 calc(var(--timer) + 5) timer-6 calc(var(--timer) + 4) timer-7 calc(var(--timer) + 3) timer-8
    calc(var(--timer) + 2) timer-9 calc(var(--timer) + 1) timer-10 calc(var(--timer) + 0);
  animation: animate-letter 2s linear infinite running, animate-rotate 1s linear infinite running,
    animate-position 13s linear infinite running;
}
.d2 {
  animation-delay: 2.5s;
}
.letter div {
  width: 20px;
  height: 222px;
  font-size: 17px;
  color: #002e00;
}
.letter div:before {
  position: absolute;
  filter: brightness(2.5) blur(0.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  content: counter(timer-1, lower-macedonian) counter(timer-2, lower-macedonian) counter(timer-3, lower-macedonian)
    counter(timer-4, lower-macedonian) counter(timer-5, lower-macedonian) counter(timer-6, lower-macedonian)
    counter(timer-7, lower-macedonian) counter(timer-8, lower-macedonian) counter(timer-9, lower-macedonian)
    counter(timer-10, lower-macedonian) counter(timer-9, lower-macedonian) counter(timer-1, lower-macedonian)
    counter(timer-17, lower-macedonian) counter(timer-5, lower-macedonian) counter(timer-17, lower-macedonian)
    counter(timer-10, lower-macedonian);
  writing-mode: vertical-rl;
  text-orientation: upright;
}
.letter div:after {
  position: absolute;
  content: counter(timer-1, lower-macedonian);
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 17px;
  align-items: center;
  justify-content: end;
  background: linear-gradient(180deg, transparent, transparent);
  writing-mode: vertical-rl;
  text-orientation: upright;
}
@keyframes animate-letter {
  to {
    --timer: 16;
  }
}
@keyframes animate-rotate {
  to {
    transform: rotateY(180deg);
  }
}
@keyframes animate-position {
  to {
    top: calc(100% + 40px);
  }
}