@import "./colors.less";
@import "./cyberpunk.less";

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-wrap: break-word;
  font-family: monospace;
  background: linear-gradient(45deg, #141823 0%, #292e49 100%);
  font-weight: lighter;
  color: #fff8f0;
  background: black;
  font-family: "Orbitron", sans-serif;
  font-style: italic;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkcyan;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkcyan;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2.5%;
}

p,
ul {
  font-family: monospace;
  margin: 2.5%;
}

video {
  max-width: 95%;
  width: 95%;
  text-align: center;
  margin: 2.5% 2.5% auto;
}

audio {
  max-width: 95%;
  width: 95%;
  text-align: center;
  margin: 2.5% 2.5% auto;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
}

.App {
  text-align: center;
}

button {
  border: 2px solid hotpink;
  background: black;
  border-radius: 5px;
  margin: 0.25em;
  padding: 0.5em 1.25em;
}

.App-header {
  background-color: @background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: @textColor;
}

.App-link {
  color: @linkColor;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: App-logo-spin infinite 20s linear;
  }
}

.calliope-admin {
  z-index: 5;
}

.calliope-container {
  z-index: 5;
}

.calliope-list-item {
  max-height: 37vh;
  padding: 0.5em;
  .rs-list-item {
    padding-left: 1.25em;
  }
}

.calliope-post,
.calliope-page {
  contain:paint;
  margin: 1.5em 0.5em 0.25em 1em;
  padding-left: 0.5em;
  text-shadow: 1px 3px 10px #008b8b;
  box-shadow: 1px 1px rgb(14, 52, 64);
  border: 1px solid rgba(00, 44, 44, 0.4);
  background: rgba(14, 52, 64, 0.7);
}

.calliope-load-post {
  padding: 1em;
  margin: 1em;
}

.calliope-site-link {
  padding: 1em;
  margin: 1em;
}

.calliope-modal {
  background: rgba(4, 21, 27, 0.99);
  display: flex;
  border: 1px solid #101f1f;

  .swal2-title {
    color: white;
  }
  .swal2-content {
    color: white;
  }
}

.calliope-input {
  background: cadetblue;
  font-size: small;
  width: 100%;
  border: none;
  color: darkslategrey;
  font-style: oblique;
  font-weight: 600;
  font-size: x-small;
  padding: 0.5em;
}

.App-nav {
  position: fixed;
  color: ghostwhite;
  background: rgba(4, 21, 27, 0.82);
  text-shadow: 1px 3px 3px darkcyan;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.f {
  z-index: 10;
  position: fixed;
  bottom: 5px;
  right: 15px;
  font-family: "Arial";
  font-size: 1rem;
  color: #d7b8f3;
  text-align: center;
  margin: revert;
}
.f a {
  font-size: 1rem;
  color: #fff;
}
.rs-panel-body-fill {
  height: 100%;
}

.bp3-non-ideal-state {
  min-height: 87vh;
  background: rgba(4, 21, 27, 0.99);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.card-back {
  overflow: hidden;
  position: relative;
  border-radius: 0.5em;
  box-shadow: 2px 2px 17px #0001;
  background: linear-gradient(var(--ang, 180deg), #0091, #000);
}
.card-back,
.card-back:before,
.card-back:after {
  --strip-stop: 100%;
  --strip-f: 0.25;
  --strip-stop-0: calc(var(--strip-f) * var(--strip-stop));
  --strip-stop-1: calc(var(--strip-stop) - var(--strip-stop-0));
  --strip-end: red;
  --strip-mid: transparent;
  --strip-list: var(--strip-end) 0, var(--strip-end) var(--strip-stop-0), var(--strip-mid) 0, var(--strip-mid) var(--strip-stop-1),
    var(--strip-end) 0, var(--strip-end) var(--strip-stop);
  --joint-list: var(--joint-end, red) var(--joint-stop, 25%), var(--joint-mid, transparent) 0;
  --joint-0: linear-gradient(135deg, var(--joint-list));
  --joint-1: linear-gradient(-135deg, var(--joint-list));
  --joint-2: linear-gradient(-45deg, var(--joint-list));
  --joint-3: linear-gradient(45deg, var(--joint-list));
}
.card-back:before,
.card-back:after {
  --i: 0;
  --noti: calc(1 - var(--i));
  --sgni: calc(2 * var(--i) - 1);
  --c: hsl(0, 0%, 0%, var(--i));
  --notc: hsl(0, 0%, 0%, var(--noti));
  --fill: linear-gradient(var(--c), var(--c));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --c0: RGB(
    calc(var(--i) * 63 + var(--noti) * 255),
    calc(var(--i) * 126 + var(--noti) * 149),
    calc(var(--i) * 166 + var(--noti) * 40)
  );
  --c1: RGB(
    calc(var(--i) * 255 + var(--noti) * 63),
    calc(var(--i) * 149 + var(--noti) * 126),
    calc(var(--i) * 40 + var(--noti) * 166)
  );
  -webkit-mask: var(--mask);
  mask: var(--mask);
  -webkit-mask-position: var(--mask-o, 50% 50%);
  mask-position: var(--mask-o, 50% 50%);
  -webkit-mask-size: var(--mask-d);
  mask-size: var(--mask-d);
  content: "";
}
.card-back:after {
  --i: 1;
}

.card-back:nth-child(4) {
  --d: 8em;
  --ang: 45deg;
}
.card-back:nth-child(4):before,
.card-back:nth-child(4):after {
  background: var(--c0);
  --strip-end: var(--c);
  --strip-mid: var(--notc);
  --strip-stop: var(--d);
  --mask: linear-gradient(90deg, red, transparent), radial-gradient(circle at 25% 25%, red 9%, transparent 35%),
    repeating-linear-gradient(90deg, var(--strip-list)), repeating-linear-gradient(-45deg, var(--strip-list));
  --mask-o: calc(50% - 0.25 * var(--d)) 50%, 50% 50%, 50% 50%, 50% 50%;
  --mask-d: calc(0.5 * var(--d)), 8px 8px, 100%, 100%;
  -webkit-mask-composite: source-in, source-in, source-in, source-over;
  mask-composite: intersect;
}

@primaryColor: rgba(37, 1, 63, 0.52);@secondaryColor: #ab00aa77;@dark: #222;@textColor: hotpink;@linkColor: hsl( 300deg, 20%, 50% );@darkGray: #444;@midGray: #777;@lightGray: #aaa;@slateGray: #30404d;@coldGray: #455;@rustGray: #544;@warmGray: #664;@primaryGrad: linear-gradient(45deg, @primaryColor, black);@secondaryGrad: linear-gradient(45deg,indigo,black);